import { Form } from 'informed'
import React, { useEffect } from 'react'
import { Button } from 'primereact/button'
import { DataTable } from 'components/DataTable/DataTable'
import { Column } from 'components/DataTable/Column'
import Swal from 'sweetalert2'

import Loading from 'components/Loading'
import Saving from 'components/Saving'
import InformedSimpleInputText from 'components/FormInputs/InformedSimpleInputText'
import { useMutation, useQuery } from '@apollo/client'
import {
    createClaimCreator,
    updateClaimCreator,
    deleteClaimCreator,
    listClaimCreatorsByProjectId
} from 'graphql/api/ClaimCreator.js'

const ClaimCreators = ({ projectId }) => {
    const informedApi = React.useRef()
    const [claimCreatorList, setClaimCreatorList] = React.useState([])
    const [uploadingData, setUploadingData] = React.useState(false)
    const {
        loading: loadingData,
        data: data,
        refetch: refetch
    } = useQuery(listClaimCreatorsByProjectId,
        {
            variables: {
                projectId: projectId
            }
        }
    )
    const [deleteClaimCreatorMutation] = useMutation(
        deleteClaimCreator
    )
    const [createClaimCreatorMutation] = useMutation(
        createClaimCreator
    )
    const [updateClaimCreatorMutation] = useMutation(
        updateClaimCreator
    )
    useEffect(() => {
        if (data?.listClaimCreators?.items) {
            setClaimCreatorList(data.listClaimCreators.items)
        }
    }, [data])

    const addClaimCreator = () => {   
        let list = [...claimCreatorList]
        list.push({
            firstName: null,
            lastName: null,
            email: null,
            phone: null
        })
        setClaimCreatorList(list)
    }

    const deleteRecord = async(claimCreator) => {
        if (claimCreator.id) {
            await Swal.fire({
                text: `Are you sure you want to delete claim creator ${claimCreator.id}?`,
                showCancelButton: true,
                confirmButtonColor: 'var(--primary-color)',
                confirmButtonText: 'Yes, delete it!',
            })
            .then(async(result) => {
                if (result.isConfirmed) {
                    deleteClaimCreatorMutation({
                        variables: {
                            id: claimCreator.id
                        }   
                    })
                    .then(() => {
                        let list = [...claimCreatorList]
                        list.splice(list.indexOf(claimCreator), 1)
                        setClaimCreatorList(list)
                    })
                }
            })
        }
        else {
            let list = [...claimCreatorList]
            list.splice(list.indexOf(claimCreator), 1)
            setClaimCreatorList(list)
        }
    }

    const submit = async(event) => {
        try {
            setUploadingData(true)
            for( const claimCreator of claimCreatorList) {
                if (!claimCreator?.id) {
                    // it is only possible to create a claim creator
                    // no update it to keep the data integrity with the user data
                    await createClaimCreatorMutation({
                        variables: {
                            input: {
                                firstName: claimCreator.firstName,
                                lastName: claimCreator.lastName,
                                email: claimCreator.email,
                                phone: claimCreator.phone,
                                projectId: projectId
                            }
                        }
                    })
                }
            }
            setUploadingData(false)
            refetch()
        }
        catch(error) {
            setUploadingData(false)
        }
    }

    if (loadingData) {
        return <Loading/>
    }

    return (
        <Form onSubmit={submit} formApiRef={ informedApi }>
            <div className='p-fluid formgrid grid mx-2 mb-5'>
                <DataTable
                    value={ claimCreatorList }
                    header={ <div className='d-flex justify-content-between align-items-center'>
                        Claim Creators
                        <Button className='p-button-outlined'
                            type='button'
                            onClick={() => addClaimCreator()}
                            style={{width: 'auto'}}
                        >
                            <i className='fa fa-plus'></i>
                            <span className='ml-2'>Add Claim Creator</span>
                        </Button>
                    </div> }
                    showGridlines
                    filterDisplay='menu'
                    className='w-full'
                    stripedRows={false}
                    resizableColumns={true}
                >
                    <Column
                        field='id'
                        header='ID'
                        sortable
                    />
                    <Column
                        field='firstName'
                        header='First Name'
                        sortable
                        body={ (data, element) => {
                            return (
                                <InformedSimpleInputText
                                    field={'ClaimCreatorFirstName'+element.rowIndex}
                                    initialValue={data.firstName}
                                    disabled={data.id}
                                    required
                                    onChange={ (e) => {
                                        let list = [...claimCreatorList]
                                        list[element.rowIndex].firstName = e.value
                                        setClaimCreatorList(list)
                                    }}
                                />
                            )
                        }}
                    />
                    <Column
                        field='lastName'
                        header='Last Name'
                        sortable
                        body={ (data, element) => {
                            return (
                                <InformedSimpleInputText
                                    field={'ClaimCreatorLastName'+element.rowIndex}
                                    initialValue={data.lastName}
                                    disabled={data.id}
                                    required
                                    onChange={ (e) => {
                                        let list = [...claimCreatorList]
                                        list[element.rowIndex].lastName = e.value
                                        setClaimCreatorList(list)
                                    }}
                                />
                            )
                        }}
                    />
                    <Column
                        field='email'
                        header='Email'
                        sortable
                        body={ (data, element) => {
                            return (
                                <InformedSimpleInputText
                                    field={'ClaimCreatorEmail'+element.rowIndex}
                                    initialValue={data.email}
                                    disabled={data.id}
                                    type='email'
                                    validate={ (e) =>  
                                        e && !e.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ?
                                        'Invalid email address' :
                                        undefined
                                    }
                                    required
                                    onChange={ (e) => {
                                        let list = [...claimCreatorList]
                                        list[element.rowIndex].email = e.value
                                        setClaimCreatorList(list)
                                    }}
                                />
                            )
                        }}
                    />
                    <Column
                        field='phone'
                        header='Phone'
                        sortable
                        body={ (data, element) => {
                            return (
                                <InformedSimpleInputText
                                    field={'ClaimCreatorPhone'+element.rowIndex}
                                    initialValue={data.phone}
                                    disabled={data.id}
                                    required
                                    onChange={ (e) => {
                                        let list = [...claimCreatorList]
                                        list[element.rowIndex].phone = e.value
                                        setClaimCreatorList(list)
                                    }}
                                />
                            )
                        }}
                    />
                    <Column
                        field='action'
                        header='Action'
                        style={ {textAlign: 'center'} }
                        body={ (data, element) => {
                            return (
                                <button className='p-button p-button-rounded p-button-text p-button-plain w-auto'
                                    type='button'
                                    onClick={() => deleteRecord(data, element)}
                                >
                                    <i className='pi pi-trash text-xl'></i>
                                </button>
                            )
                        }}
                    />
                </DataTable>           
            </div>
            <div className='p-fluid formgrid grid justify-content-end mx-2'>
                <Button label={uploadingData ? <Saving/> : 'Save'}
                    type='submit'
                    style={{maxWidth: '100px'}}
                />
            </div>
        </Form>
    )
}

ClaimCreators.propTypes = {}

export default ClaimCreators
