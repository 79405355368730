import classNames from 'classnames'
import 'font-awesome/css/font-awesome.css'
import 'fullcalendar/dist/fullcalendar.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import { Button } from 'primereact/button'
import 'primereact/resources/primereact.min.css'
import React, { useState, useEffect, useMemo } from 'react'

import { withRouter } from 'react-router-dom'
import NewMessage from '../components/common/NewMessage'
import './App.css'
import { AppFooter } from './AppFooter'
import AppInlineProfile from './AppInlineProfile'
import AppMenu from './AppMenu'
import { AppRouter } from './AppRouter'
import AppTopbar from './AppTopbar'
import UserSessionContext from 'components/context/UserSessionContext'
import {
    COGNITO_AUTH_CLAIM_CREATOR_GROUP,
    COGNITO_AUTH_ADMIN_GROUP,
    COGNITO_AUTH_SITE_ACCESS_GROUP,
} from 'constants/general'
import Loading from './Loading'
import ClaimCreators from './ADR/Project/View/ClaimCreators'

const App = (props) => {
    const [menuActive, setMenuActive] = useState(false)
    const [profileMode, setProfileMode] = useState('inline')
    const [menuMode, setMenuMode] = useState('static')
    const [overlayMenuActive, setOverlayMenuActive] = useState(false)
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false)
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false)
    const [topbarMenuActive, setTopbarMenuActive] = useState(false)
    const [activeTopbarItem, setActiveTopbarItem] = useState(null)
    const [darkMenu, setDarkMenu] = useState(true)
    const [theme, setTheme] = useState('blue')
    const [layout, setLayout] = useState('blue')
    const [configActive, setConfigActive] = useState(false)
    const [inputStyle, setInputStyle] = useState('outlined')
    const [ripple, setRipple] = useState(false)
    const [newMsgVisible, setNewMsgVisible] = useState(false)
    const userSession = React.useContext(UserSessionContext)

    let menuClick = false
    let topbarItemClick = false
    let configClick = false

    const [menu, setMenu] = useState([])

    const claimCreatorMenu = useMemo(() => [
        {
            label: 'Infinity RMIS - Claims',
            items: [
                {
                    label: 'Open Claims',
                    icon: 'fa fa-fw fa-unlock',
                    command: () => {
                        window.location = '#/openclaims'
                    },
                },
                {
                    label: 'New Claim',
                    icon: 'fa fa-fw fa-plus',
                    command: () => {
                        window.location = '#/claim/new'
                    },
                }
            ],
        },
    ], [])

    const siteAccessMenu = useMemo(() => [
        {
            label: 'ADR Systems',
            items: [
                {
                    label: 'Projects',
                    icon: 'fa fa-fw fa-object-group',
                    command: (e) => {
                        window.location = '#/adr/projects'
                    },
                },
            ],
        },
        {
            label: 'WRAP-PRO',
            items: [
                {
                    label: 'Site Access',
                    icon: 'pi pi-users',
                    command: (e) => {
                        window.location = '#/wrappro/siteaccess'
                    }
                },
            ],
        }
    ], [])


    const adminMenu = useMemo(() => [
        {
            label: 'Unity Vendor Optimization',
            items: [
                {
                    label: 'Director Dashboard',
                    icon: 'fa fa-fw fa-bullhorn',
                    command: () => {
                        window.location = '#/demo/director-dashboard'
                    },
                },
                {
                    label: 'Invoicing',
                    icon: 'fa fa-fw fa-bank',
                    command: () => {
                        window.location = '#/demo/invoicing'
                    },
                },
                {
                    label: 'Vendor Market',
                    icon: 'fa fa-fw fa-balance-scale',
                    command: () => {
                        window.location = '#/demo/vendor-marketplace'
                    },
                },
                {
                    label: 'Referrals',
                    icon: 'fa fa-fw fa-users',
                    command: () => {
                        window.location = '#/demo/referrals'
                    }
                },
                {
                    label: 'Vendor Work',
                    icon: 'fa fa-fw fa-edit',
                    command: () => {
                        window.location = '#/demo/vendor-work'
                    },
                },
                {
                    label: 'Adjuster Dashboard',
                    icon: 'fa fa-fw fa-home',
                    command: () => {
                        window.location = '#/dashboard'
                    },
                },
            ],
        },
        {
            label: 'Infinity RMIS - Claims',
            items: [
                {
                    label: 'Director Dashboard',
                    icon: 'fa fa-fw fa-bullhorn',
                    command: () => {
                        window.location = '#/demo/director-dashboard'
                    },
                },
                {
                    label: 'Adjuster Dashboard',
                    icon: 'fa fa-fw fa-home',
                    command: () => {
                        window.location = '#/dashboard'
                    },
                },
                {
                    label: 'Open Claims',
                    icon: 'fa fa-fw fa-unlock',
                    command: () => {
                        window.location = '#/openclaims'
                    },
                },
                {
                    label: 'Check Run',
                    icon: 'fa fa-fw fa-print',
                    command: () => {
                        window.location = '#/checkrun'
                    },
                },
                {
                    label: 'Diaries',
                    icon: 'fa fa-fw fa-book',
                    command: () => {
                        window.location = '#/diaries'
                    },
                },
                {
                    label: 'New Claim',
                    icon: 'fa fa-fw fa-plus',
                    command: () => {
                        window.location = '#/claim/new'
                    },
                },
                {
                    label: 'Loss Ratio Master',
                    icon: 'fa fa-fw fa-percent',
                    command: () => {
                        window.location = '#/demo/loss-ratio-master'
                    },
                },
                {
                    label: 'Analog Management',
                    icon: 'fa fa-fw fa-upload',
                    command: () => {
                        window.location = '#/demo/loss-run-upload'
                    },
                },
            ],
        },
        {
            label: 'ADR Systems',
            items: [
                {
                    label: 'Dashboard',
                    icon: 'pi pi-chart-line',
                    command: (e) => {
                        window.location = '#/adr/dashboard'
                    },
                },
                {
                    label: 'Open Claims',
                    icon: 'fa fa-fw fa-unlock',
                    command: () => {
                        window.location = '#/openclaims'
                    },
                },
                {
                    label: 'New Claim',
                    icon: 'fa fa-fw fa-plus',
                    command: () => {
                        window.location = '#/claim/new'
                    },
                },
                {
                    label: 'Agenda Claims',
                    icon: 'pi pi-server',
                    command: (e) => {
                        window.location = '#/adr/openclaims'
                    },
                },
                {
                    label: 'New Project',
                    icon: 'pi pi-link',
                    command: (e) => {
                        window.location = '#/adr/project'
                    },
                },
                {
                    label: 'Projects',
                    icon: 'fa fa-fw fa-object-group',
                    command: (e) => {
                        window.location = '#/adr/projects'
                    },
                },
                {
                    label: 'New Employer',
                    icon: 'pi pi-users',
                    command: (e) => {
                        window.location = '#/adr/employer'
                    }
                },
                {
                    label: 'Employers',
                    icon: 'fa fa-fw fa-users',
                    command: (e) => {
                        window.location = '#/adr/employers'
                    }
                },
                {
                    label: 'Developers',
                    icon: 'fa fa-fw fa-code',
                    command: (e) => {
                        window.location = '#/adr/developers'
                    }
                },
                {
                    label: 'Partners',
                    icon: 'fa fa-fw fa-handshake-o',
                    command: (e) => {
                        window.location = '#/adr/partners'
                    }
                },
                {
                    label: 'Contractors',
                    icon: 'fa fa-fw fa-wrench',
                    command: (e) => {
                        window.location = '#/adr/contractors'
                    },
                },
                {
                    label: 'Mobile Demo',
                    icon: 'pi pi-ticket',
                    command: (e) => {
                        window.location = '#/adr/mobile'
                    },
                },
                // {
                //     label: 'Dispute Detail',
                //     icon: 'fa fa-fw fa-id-card-o',
                //     command: (e) => {
                //         window.location = '#/adr/detail'
                //     },
                // },
            ],
        },
        {
            label: 'Resources',
            items: [
                {
                    label: 'Reports',
                    icon: 'fa fa-fw fa-bar-chart',
                    command: () => {
                        window.location = '#/reports'
                    },
                },
                {
                    label: 'Help',
                    icon: 'fa fa-fw fa-question',
                    command: () => {
                        window.location = 'mailto:help@asgnational.com'
                    },
                },
            ],
        },
        {
            label: 'WRAP-PRO',
            items: [
                {
                    label: 'Dashboard',
                    icon: 'pi pi-chart-line',
                    command: (e) => {
                        window.location = '#/wrappro/dashboard'
                    },
                },
                {
                    label: 'Projects',
                    icon: 'fa fa-fw fa-object-group',
                    command: (e) => {
                        window.location = '#/adr/projects'
                    },
                },
                {
                    label: 'New Project',
                    icon: 'pi pi-link',
                    command: (e) => {
                        window.location = '#/adr/project'
                    },
                },
                {
                    label: 'Site Access',
                    icon: 'pi pi-users',
                    command: (e) => {
                        window.location = '#/wrappro/siteaccess'
                    }
                },
                {
                    label: 'Payroll & Loss',
                    icon: 'fa fa-fw fa-users',
                    command: (e) => {
                        window.location = '#/404'
                    }
                },
                {
                    label: 'Carrier Mgmt',
                    icon: 'fa fa-fw fa-code',
                    command: (e) => {
                        window.location = '#/adr/carrier'
                    }
                },
            ],
        }
    ], [])

    useEffect(() => {
        if (userSession) {
            if (userSession?.groups?.includes(COGNITO_AUTH_ADMIN_GROUP)) {
                setMenu(adminMenu)
            }
            else if (userSession?.groups?.includes(COGNITO_AUTH_CLAIM_CREATOR_GROUP)) {
                setMenu(claimCreatorMenu)
            }
            else if (userSession?.groups?.includes(COGNITO_AUTH_SITE_ACCESS_GROUP)) {
                setMenu(siteAccessMenu)
            }
        }
    }, [userSession, claimCreatorMenu, adminMenu, siteAccessMenu])

    const onDocumentClick = () => {
        if (!topbarItemClick) {
            setActiveTopbarItem(null)
            setTopbarMenuActive(false)
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false)
            }

            hideOverlayMenu()
        }

        if (configActive && !configClick) {
            setConfigActive(false)
        }

        menuClick = false
        topbarItemClick = false
        configClick = false
    }


    const onMenuClick = () => {
        menuClick = true
    }

    const onMenuitemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu()
        }

        if (!event.item.items && (isSlim() || isHorizontal())) {
            setMenuActive(false)
        }
    }

    const onRootMenuitemClick = () => {
        setMenuActive((prevMenuActive) => !prevMenuActive)
    }

    const onMenuButtonClick = (event) => {
        menuClick = true
        setTopbarMenuActive(false)

        if (menuMode === 'overlay') {
            setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive)
        } else {
            if (isDesktop()) setStaticMenuDesktopInactive((prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive)
            else setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive)
        }

        event.preventDefault()
    }

    const onTopbarMenuButtonClick = (event) => {
        topbarItemClick = true
        setTopbarMenuActive((prevTopbarMenuActive) => !prevTopbarMenuActive)
        hideOverlayMenu()
        event.preventDefault()
    }

    const onTopbarItemClick = (e) => {
        topbarItemClick = true

        if (activeTopbarItem === e.item) setActiveTopbarItem(null)
        else setActiveTopbarItem(e.item)

        e.originalEvent.preventDefault()
    }

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false)
        setStaticMenuMobileActive(false)
    }

    const isDesktop = () => {
        return window.innerWidth > 1024
    }

    const isHorizontal = () => {
        return menuMode === 'horizontal'
    }

    const isSlim = () => {
        return menuMode === 'slim'
    }

    const isMenuVisible = () => {
        if (isDesktop()) {
            if (menuMode === 'static') return !staticMenuDesktopInactive
            else if (menuMode === 'overlay') return overlayMenuActive
            else return true
        } else {
            if (staticMenuMobileActive) return true
            else if (menuMode === 'overlay') return overlayMenuActive
            else return true
        }
    }

    const onMenuModeChange = (e) => {
        setMenuMode(e.value)
        setStaticMenuDesktopInactive(false)
        setOverlayMenuActive(false)

        if (e.value === 'horizontal') {
            setProfileMode('top')
        }
    }

    const onMenuColorChange = (e) => {
        setDarkMenu(e.value)
    }

    const onProfileModeChange = (e) => {
        setProfileMode(e.value)
    }

    const onConfigClick = () => {
        configClick = true
    }

    const onConfigButtonClick = () => {
        setConfigActive((prevConfigActive) => !prevConfigActive)
        configClick = true
    }

    const onLayoutColorChange = (color) => {
        setLayout(color)
    }

    const onThemeChange = (color) => {
        setTheme(color)
    }

    let containerClassName = classNames('layout-wrapper', {
        'menu-layout-static': menuMode !== 'overlay',
        'menu-layout-overlay': menuMode === 'overlay',
        'layout-menu-overlay-active': overlayMenuActive,
        'menu-layout-slim': menuMode === 'slim',
        'menu-layout-horizontal': menuMode === 'horizontal',
        'layout-menu-static-inactive': staticMenuDesktopInactive,
        'layout-menu-static-active': staticMenuMobileActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': !ripple,
    })
    let menuClassName = classNames('layout-menu-container', {
        'layout-menu-dark': darkMenu,
        'layout-menu-container-inactive': !isMenuVisible(),
    })


    return (
        <div className={ containerClassName } onClick={ onDocumentClick }>
            <NewMessage
                visible={ newMsgVisible }
                onClose={ () => {
                    setNewMsgVisible(false)
                } }
                position={ {top: 0, left: 0} }
            />
            <div className='layout-wrapper-content'>
                <AppTopbar
                    topbarMenuActive={ topbarMenuActive }
                    profileMode={ profileMode }
                    horizontal={ isHorizontal() }
                    activeTopbarItem={ activeTopbarItem }
                    onMenuButtonClick={ onMenuButtonClick }
                    onTopbarMenuButtonClick={ onTopbarMenuButtonClick }
                    onTopbarItemClick={ onTopbarItemClick }
                ></AppTopbar>

                <div className={ menuClassName } onClick={ onMenuClick }>
                    <div className='menu-scroll-content'>
                        { profileMode === 'inline' && menuMode !== 'horizontal' && (
                            <AppInlineProfile/>
                        ) }

                        <AppMenu
                            model={ menu }
                            menuMode={ menuMode }
                            active={ menuActive }
                            onMenuitemClick={ onMenuitemClick }
                            onRootMenuitemClick={ onRootMenuitemClick }
                        ></AppMenu>
                    </div>
                </div>

                <div className='layout-main'>
                    { props.error ? (
                        <>
                            <h2>An unexpected error has occurred</h2>
                            {/* <div>{this.props.error.error.toString()}</div> */ }
                            {/* <div>{this.props.error.componentStack}</div> */ }
                            <Button
                                label='Click here to reset!'
                                onClick={ () => {
                                    // this.props.error.resetError()
                                    window.location = '#'
                                    window.location.reload()
                                } }
                            />
                        </>
                    ) : (
                        userSession ? <AppRouter /> : <Loading/>
                    ) }
                </div>

                {/* <AppConfig
                configActive={configActive}
                menuMode={menuMode}
                onMenuModeChange={onMenuModeChange}
                darkMenu={darkMenu}
                onMenuColorChange={onMenuColorChange}
                profileMode={profileMode}
                onProfileModeChange={onProfileModeChange}
                onConfigClick={onConfigClick}
                onConfigButtonClick={onConfigButtonClick}
                layout={layout}
                onLayoutColorChange={onLayoutColorChange}
                theme={theme}
                onThemeChange={onThemeChange}
                rippleActive={ripple}
                onRippleChange={onRippleChange}
                inputStyle={inputStyle}
                onInputStyleChange={onInputStyleChange}
                ></AppConfig> */ }

                <div className='layout-mask modal-in'></div>

                <AppFooter />
            </div>
        </div>
    )
}

export default withRouter(App)
