import UserSessionContext from './context/UserSessionContext'
import React, { lazy, Suspense, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import {
    COGNITO_AUTH_DEMO_GROUP,
    COGNITO_AUTH_ADMIN_GROUP,
    COGNITO_AUTH_SITE_ACCESS_GROUP
} from 'constants/general'
import CarrierManagement from './CarrierManagement/CarrierManagement'


export const AppRouter = () => {

    const NotFound = lazy(() => import('./NotFound'))
    const Dashboard = lazy(() => import(/* webpackChunkName: "Dashboard" */ '../components/Dashboard/Dashboard'))
    const NotificationSettings = lazy(() => import(/* webpackChunkName: "Dashboard" */ '../components/Settings/Notification'))
    const ClaimantPage = lazy(() => import(/* webpackChunkName: "ClaimantPage" */ './Claimant/ClaimantPage'))
    const NewClaim = lazy(() => import(/* webpackChunkName: "NewClaim" */ './NewClaim/'))
    const Reports = lazy(() => import(/* webpackChunkName: "Reports" */ './Reports/'))
    const ClaimPayment = lazy(() => import(/*webpackChunkName: "ClaimPayment" */ './Claims/ClaimPayment/ClaimPayment.js'))
    const Diaries = lazy(() => import(/*webpackChunkName: "Diaries" */ '../components/Diaries/Diaries'))
    const CheckRun = lazy(() => import(/*webpackChunkName: "CheckRun" */ './CheckRun/CheckRun.js'))
    const OpenClaims = lazy(() => import(/*webpackChunkName: "OpenClaims" */ './OpenClaims/OpenClaims.js'))
    const LossRunUpload = lazy(() => import(/*webpackChunkName: "LossRunUpload" */ './LossRunUpload/index.js'))
    const ADRDetail = lazy(() => import(/* webpackChunkName: "ADRDetail" */ './ADR/Detail'))
    const ADRProject = lazy(() => import(/* webpackChunkName: "Project" */ './ADR/Project'))
    const ADRDashboard = lazy(() => import(/* webpackChunkName: "ADRDashboard" */ './ADR/ADRDashboard'))
    const WrapProDashboard = lazy(() => import(/* webpackChunkName: "WrapProDashboard" */ './WrapPro/Dashboard'))
    const WrapProSiteAccess = lazy(() => import(/* webpackChunkName: "WrapProSiteAccess" */ './WrapPro/SiteAccess'))
    const ADRDevelopers = lazy(() => import('./ADR/Developers'))
    const ADRProjectPartners = lazy(() => import('./ADR/ProjectPartners'))
    const ADRContractors = lazy(() => import('./ADR/Contractors'))
    const ADRMobile = lazy(() => import('./ADR/Mobile'))
    const Projects = lazy(() => import('./ADR/Projects'))
    const ADREmployer = lazy(() => import('./ADR/Employer'))
    const ADREmployers = lazy(() => import('./ADR/Employers'))
    const ADROpenClaims = lazy(() => import('./ADR/ADROpenClaims'))
    const DirectorDashboard = lazy(() => import('./Demo/components/containers/DirectorDashboardPage'))
    const InvoicingPage = lazy(() => import('./Demo/components/containers/InvoicingPage'))
    const VendorMarketplacePage = lazy(() => import('./Demo/components/containers/VendorMarketplacePage'))
    const OpenReferralsPage = lazy(() => import('./Demo/components/containers/OpenReferralsPage'))
    const VendorsPage = lazy(() => import('./Demo/components/containers/VendorsPage'))
    const VendorWorkPage = lazy(() => import('./Demo/components/containers/VendorWorkPage'))
    const LossRatioMaster = lazy(() => import('./Demo/components/LossRatioMaster'))
    const LossRunUploadDemo = lazy(() => import('./Demo/components/LossRunUpload'))
    const ClaimantPageDemo = lazy(() => import('./Demo/components/Claimant/ClaimantPage'))
    const ReferralPageDemo = lazy(() => import('./Demo/components/containers/ReferralPage'))
    const Stats = lazy(() => import('./Demo/components/containers/Stats'))
    const TimelineDetailPageDemo = lazy(() => import('./Demo/components/containers/TimelineDetailPage'))
    const NewReferralPageDemo = lazy(() => import('./Demo/components/containers/NewReferralPage'))

    const userSession = React.useContext(UserSessionContext)

    useEffect(() => {if (!userSession) { return undefined } }, [userSession])

    return (
        <Suspense fallback={ <div>Loading...</div> }>
            <Switch>
                <Route path='/404'>
                    <NotFound/>
                </Route>
                <Route path='/' exact>
                    {  
                        () => {
                            if(userSession?.groups?.includes(COGNITO_AUTH_ADMIN_GROUP))
                                return <Dashboard />
                            else if(userSession?.groups?.includes(COGNITO_AUTH_SITE_ACCESS_GROUP))
                                return <Projects />
                            else
                                return <OpenClaims />
                        }
                    }
                </Route>
                <Route path='/settings/notification'>
                    <NotificationSettings />
                </Route>
                <Route path='/dashboard'>
                    <Dashboard />
                </Route>
                <Route path='/claimant/:id'>
                    <ClaimantPage />
                </Route>

                <Route path='/reports'>
                    <Reports />
                </Route>
                <Route path='/claim/new/'>
                    <NewClaim />
                </Route>
                <Route path='/newref'>
                    <NewReferralPageDemo />
                </Route>
                <Route path='/claim/appendchild/:parentClaimId?'>
                    <NewClaim appendChildClaim={ true } />
                </Route>
                <Route path='/claim/:claimId'>
                    <NewClaim/>
                </Route>

                <Route path='/claim/payment'>
                    <ClaimPayment />
                </Route>
                <Route path='/diaries'>
                    <Diaries />
                </Route>
                <Route path='/checkrun'>
                    <CheckRun />
                </Route>
                <Route path='/openclaims'>
                    <OpenClaims />
                </Route>
                <Route path='/lossrunupload'>
                    <LossRunUpload />
                </Route>
                <Route path='/ADR/detail'>
                    <ADRDetail />
                </Route>
                <Route path='/ADR/project'>
                    <ADRProject />
                </Route>
                <Route path='/ADR/projects'>
                    <Projects />
                </Route>
                <Route path='/ADR/employer'>
                    <ADREmployer />
                </Route>
                <Route path='/ADR/employers'>
                    <ADREmployers />
                </Route>
                <Route path='/ADR/dashboard'>
                    <ADRDashboard />
                </Route>
                <Route path='/ADR/developers'>
                    <ADRDevelopers/>
                </Route>
                <Route path='/ADR/partners'>
                    <ADRProjectPartners />
                </Route>
                <Route path='/ADR/contractors'>
                    <ADRContractors />
                </Route>
                <Route path='/ADR/mobile'>
                    <ADRMobile />
                </Route>
                
                <Route path='/ADR/openclaims'>
                    <ADROpenClaims />
                </Route>
                <Route path='/ADR/carrier'>
                    <CarrierManagement />
                </Route>

                <Route path='/wrappro/dashboard'>
                    <WrapProDashboard />
                </Route>
                <Route path='/wrappro/siteaccess'>
                    <WrapProSiteAccess />
                </Route>
            </Switch>

            <Switch>
                <Route path='/demo/director-dashboard'>
                    <DirectorDashboard />
                </Route>
                <Route path='/demo/invoicing'>
                    <InvoicingPage />
                </Route>
                <Route path='/demo/vendor-marketplace'>
                    <VendorMarketplacePage />
                </Route>
                <Route path='/demo/referrals'>
                    <OpenReferralsPage />
                </Route>
                <Route path='/demo/vendors'>
                    <VendorsPage />
                </Route>
                <Route path='/demo/vendor-work'>
                    <VendorWorkPage />
                </Route>
                <Route path='/demo/loss-ratio-master'>
                    <LossRatioMaster />
                </Route>
                <Route path='/demo/loss-run-upload'>
                    <LossRunUploadDemo />
                </Route>
                <Route path='/demo/claimant/:id'>
                    <ClaimantPageDemo />
                </Route>
                <Route path='/demo/referral/:id'>
                    <ReferralPageDemo/>
                </Route>
                <Route path='/demo/stats/:id'>
                    <Stats/>
                </Route>
                <Route path='/demo/timeline/:id'>
                    <TimelineDetailPageDemo/>
                </Route>
            </Switch>
        </Suspense>
    )
}

export default AppRouter
