import { useField } from 'informed'
import { InputTextarea } from 'primereact/inputtextarea'
import PropTypes from 'prop-types'
import React from 'react'

const InformedInputTextArea = (props) => {
    const {render, informed, userProps, ref, fieldState, fieldApi} = useField(props) // basic informed api for
                                                                                     // implementing custom fields
    const {value, error, showError} = fieldState // field state
    const {setValue, setTouched} = fieldApi // field control
    const {field, label, cols, id, ...rest} = userProps // user (parent-caller) settings


    return render(
        <div className={ `field col-12 md:col-${ cols }` }>
            <label htmlFor={ field }>{ label }</label>
            <InputTextarea
                { ...informed }
                { ...rest }
                id={ id }
                ref={ ref }
               
                value={ value }
                onChange={ (e) => {
                    setValue(e.target.value, e)
                } }
                onBlur={ (e) => {
                    setTouched(true, e)
                } }
                style={ showError ? {border: 'solid 1px red', ...rest?.style } : { ...rest?.style }}
            />
            {
                error && showError ? <small id={ `error${ field }` } className='p-error'>{ error }</small> : null
            }
        </div>
    )
}

InformedInputTextArea.propTypes = {
    field: PropTypes.string.isRequired
    // cols: PropTypes.number.isRequired review this param for responsive design does not make sense
}


export default InformedInputTextArea
