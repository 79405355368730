import { useField } from 'informed'
import { InputText } from 'primereact/inputtext'
import PropTypes from 'prop-types'
import React from 'react'

const InformedInputText = (props) => {
    const {render, informed, userProps, ref, fieldState, fieldApi} = useField(props) // basic informed api for
                                                                                     // implementing custom fields
    const {value, error, showError} = fieldState // field state
    const {setValue, setTouched} = fieldApi // field control
    const {field, label, cols, id, ...rest} = userProps // user (parent-caller) settings

    return render(
        <div className={ `field col-12 md:col-${ cols } ${error ? 'mb-0': ''}` }>
            <label htmlFor={ field }>{ label }</label>
            <InputText
                { ...informed }
                { ...rest }
                id={ id }
                ref={ ref }
                value={ !value && value !== 0 ? '' : value }
                onChange={ (e) => {
                    setValue(e.target.value, e)
                    if (error) {
                        setTouched(true, e)
                    }
                } }
                onBlur={ (e) => {
                    setTouched(true, e)
                } }
                style={ showError ? {border: 'solid 1px red'} : null }
            />
            {
                error && showError ? <small id={ `error${ field }` } className='p-error'>{ error }</small> : null
            }
        </div>
    )
}

InformedInputText.propTypes = {
    field: PropTypes.string.isRequired,
    label: PropTypes.string
    // cols: PropTypes.number review this param for responsive design does not make sense
}

export default InformedInputText
