import UserSessionContext from './context/UserSessionContext'
import React, { lazy, Suspense, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'


export const FreeAppRouter = () => {

    const Contract = lazy(() => import('./ContractorPortal/Contract'))
    const ContractorEnrollment = lazy(() => import('./ContractorPortal/ContractorEnrollment'))
    const Messages = lazy(() => import('./ContractorPortal/Messages'))
    const NotFound = lazy(() => import('./NotFound'))
    const userSession = React.useContext(UserSessionContext)

    useEffect(() => {if (!userSession) { return undefined } }, [userSession])

    return (
        <Suspense fallback={ <div>Loading...</div> }>
            <Switch>
                <Route path='/' exact>
                    <NotFound/>
                </Route>
                <Route path='/contract' exact>
                    <Contract />
                </Route>
                <Route path='/contractor-enrollment' exact>
                    <ContractorEnrollment />
                </Route>
                <Route path='/messages' exact>
                    <Messages />
                </Route>
            </Switch>
        </Suspense>
    )
}

export default FreeAppRouter
