import UserSessionContext from 'components/context/UserSessionContext'
import { Form } from 'informed'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import InformedDropdown from 'components/FormInputs/InformedDropdown'
import InformedInputTextArea from 'components/FormInputs/InformedInputTextArea'
import InformedInputText from 'components/FormInputs/InformedInputText'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useQuery } from '@apollo/client'
import Loading from 'components/Loading'
import Saving from 'components/Saving'
import { parseFormData } from 'utils'
import {
    createClaimMessage,
} from 'graphql/api/ClaimMessage.js'
import {
    listAsgUsers,
} from 'graphql/api/AsgUser.js'

import './NewMessage.css'

const NewMessageForm = (props) => {
    const [recipients, setRecipients] = useState([])
    const [uploadingData, setUploadingData] = useState(false)
    const [isEmail, setIsEmail] = React.useState(false)
    const formRef = useRef()
    const {
        loading: loading,
        data: data
    } = useQuery(listAsgUsers)
    const userSession = useContext(UserSessionContext)

    const {isReply, replyTarget, onAfterReply} = props // called from the reply button on the message grid
    const {claimId} = props // called from the actions action dropdown from within a claim

    const [
        createClaimMessageMutation,
    ] = useMutation(createClaimMessage)

    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    const submit = async(formState) => {
        let isClaimNote = false
        if (formState.values.toUserId === 'ClaimNote') {
            formState.values.toUserId = null
            isClaimNote = true
        }
        setUploadingData(true)
        await createClaimMessageMutation({
            variables: {
                input: parseFormData(
                    {
                        claimId: claimId,
                        fromUserId: userSession.userId,
                        toUserId: replyTarget?.fromUserId,
                        message: null,
                        status: 'unread',
                        isClaimNote,
                        toEmail: null,
                    },
                    formState.values,
                )
            }
        })
        .then(() => {
            if (props.refetchClaim) {
                props.refetchClaim()
            }
            if (isReply) {
                if (onAfterReply) {
                    onAfterReply()
                }
            }
        })
        props.onClose()
    }

    useEffect(() => {
        if(data?.listAsgUsers?.items) {
            setRecipients([
                { id: 'ClaimNote', firstName: 'Claim', lastName: 'Note' },
                ...data.listAsgUsers.items])
        }
    }, [data]) 

    if (loading) {
        return <Loading/>
    }


    return (
        <Form onSubmit={ submit } formApiRef={ formRef }>
            <div className='p-fluid formgrid grid'>
                { isReply && (
                        <div className='md:col-6 sm:col-12 mx-2'>
                            Replying to&nbsp;
                            <strong>
                                { replyTarget?.fromUser?.firstName + ' ' + replyTarget?.fromUser?.lastName }
                                &apos;s
                            </strong>
                            &nbsp;message:
                            <br/>
                            { replyTarget.message }
                        </div>
                    )
                }
                {
                    !isReply && (
                        <>
                        <div className='md:col-6 sm:col-12'>
                            <InformedDropdown
                                field='toUserId'
                                options={
                                    recipients.map((recipient) => {
                                        return {
                                            value: recipient.id,
                                            label: recipient?.firstName + ' ' + recipient?.lastName,
                                        }
                                    })
                                }
                                required={!isEmail}
                                style={ {width: '165px'} }
                                placeholder='Select a recipient:'
                            />
                        </div>
                        <div className='md:col-6 sm:col-12'>
                            <InformedInputText 
                                field='toEmail'
                                className='mt-4'
                                placeholder='Email address'
                                onChange={(e) => {
                                    setIsEmail(e.value ? true : false)
                                }}
                                autocomplete="on"
                                required={isEmail}
                                validate={(value) => {
                                    if (isEmail && !isValidEmail(value)) {
                                        return 'Invalid Email'
                                    }
                                }}
                            />
                        </div>
                        </>
                    )
                }
                <div className='col-12'>
                    <InformedInputTextArea
                        style={{height: '220px', width: '100%'}}
                        field='message'
                        required
                        placeholder='Enter your message here'
                    />
                </div>
            </div>
            <div className="p-dialog-footer pb-0">
                <Button label="Cancel" onClick={props.onClose} className="p-button-text" type='button'/>
                <Button label={uploadingData ? <Saving/> : 'Save'} type="submit"/>
            </div>
        </Form>
    )
}    

const NewMessage = (props) => {
    return (
        <span className='NewMessageContainer'>
        <Dialog
            header={
                <span>
                    <i className='fa fa-envelope-o' />
                    &nbsp;&nbsp; Enter a message
                    RE: claim { props.claimId }
                </span>
            }
            className='newMsgDialog'
            visible={ props.visible }
            modal={ true }
            style={{height: '460px'}}
            onHide={ props.onClose }
        >
            {
                props.visible && (
                    <NewMessageForm
                        claimId={ props.claimId }
                        isReply={ props.isReply }
                        replyTarget={ props.replyTarget }
                        onClose={ props.onClose }
                        refetchClaim={ props.refetchClaim }
                        onAfterReply={ props.onAfterReply }
                    />
                )
            }
        </Dialog>
    </span>
    )
}

NewMessage.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
}

export default NewMessage
