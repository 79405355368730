// temporary implementation for public api wich doesn't support cognito
//check if domain is localhost or not, this should be updated to use env variables and not hardcoded
const temp_dev_env = 'https://h2q1133ql8.execute-api.us-east-1.amazonaws.com'
const apiURL = window.location.hostname === 'localhostTest' ? 'http://localhost:8000': temp_dev_env


async function publicClient(endpoint, {body, method, headers: customHeaders, ...customConfig} = {}) {
    const config = {
        method: method ? method : 'POST',
        body: body ? JSON.stringify(body) : undefined,
        headers: {
            'Content-Type': body ? 'application/json' : undefined,
            Accept: body ? 'application/json' : undefined,
            ...customHeaders,
        },
        ...customConfig,
    }

    return window.fetch(`${ apiURL }/${ endpoint }`, config).then(async(response) => {
        if (response.status === 401) {
            return Promise.reject({message: '401 Unauthorized'})
        }

        let apiMessage = response.headers.get('apimessage')
        if (apiMessage && apiMessage !== '') {
            console.log(apiMessage)
            return Promise.reject(apiMessage)
        }

        const data = await response.json()

        if (response.ok) {
            return data
        } else {
            var error = new Error(response.statusText)
            error.response = response
            return Promise.reject(error)
        }
    })
}

export { publicClient }
