import { Auth } from 'aws-amplify'
import classNames from 'classnames'
import React from 'react'
import { CSSTransition } from 'react-transition-group'
import packageJson from '../../package.json'
import UserSessionContext from 'components/context/UserSessionContext'
import { useHistory } from 'react-router-dom'
import {
    COGNITO_AUTH_DEMO_GROUP,
    COGNITO_AUTH_ADMIN_GROUP
} from 'constants/general'


const SettingsMenus = () => {
    const history = useHistory()
    return (
        <div className="ml-2">
            <button className='p-link' onClick={() => 
                history.push('/settings/notification')
            }>
                <i className='pi pi-fw pi-bell' />
                <span>Notifications</span>
            </button>
        </div>
    )
}

const AppInlineProfile = (props) => {
    const [profileExpanded, setProfileExpanded] = React.useState(false)
    const onClick = (event) => {
        setProfileExpanded(!profileExpanded)
        event.preventDefault()
    }

    const signOut = () => {
        Auth.signOut().then(() => window.location.reload())
    }

    const userSession = React.useContext(UserSessionContext)
    const [openSettings, setOpenSettings] = React.useState(false)

    const profileClassName = classNames('profile', {'profile-expanded': profileExpanded})
    const tabIndex = profileExpanded ? null : '-1'

    return (
        <div>
            <div className={ profileClassName }>
                <button className='p-link' onClick={ onClick }>
                            <span>
                                <span className='profile-name'>
                                    { userSession && userSession.name }

                                </span>
                                <span
                                    className='profile-role'>{
                                        userSession?.groups?.includes(COGNITO_AUTH_ADMIN_GROUP) ?
                                        COGNITO_AUTH_ADMIN_GROUP.toUpperCase() :
                                        userSession?.groups?.[0].toUpperCase()
                                    }
                                    {
                                        userSession?.name &&
                                        <i className='pi pi-fw pi-chevron-down ml-2'></i>
                                    }
                                </span>
                            </span>
                </button>
            </div>

            <CSSTransition
                classNames='layout-profile-menu'
                timeout={ {enter: 1000, exit: 400} }
                in={ profileExpanded }
                unmountOnExit
            >
                <ul className='layout-menu profile-menu'>
                    <li role='menuitem'>
                        <button className='p-link' tabIndex={ tabIndex }>
                            <i className='pi pi-fw pi-user'></i>
                            <span>Profile</span>
                        </button>
                        <div className='layout-menu-tooltip'>
                            <div className='layout-menu-tooltip-arrow'></div>
                            <div className='layout-menu-tooltip-text'>Profile</div>
                        </div>
                    </li>

                    <li role='menuitem'>
                        <button className='p-link' tabIndex={ tabIndex }
                            onClick={() => setOpenSettings(!openSettings)}
                        >
                            <i className='pi pi-fw pi-cog'></i>
                            <span>Settings</span>
                        </button>
                        { openSettings && <SettingsMenus/> }
                        <div className='layout-menu-tooltip'>
                            <div className='layout-menu-tooltip-arrow'></div>
                            <div className='layout-menu-tooltip-text'>Settings</div>
                        </div>
                    </li>
                    <li role='menuitem'>
                        <button className='p-link' tabIndex={ tabIndex } onClick={ signOut }>
                            <i className='pi pi-fw pi-sign-out'></i>
                            <span>Logout</span>
                        </button>
                        <div className='layout-menu-tooltip'>
                            <div className='layout-menu-tooltip-arrow'></div>
                            <div className='layout-menu-tooltip-text'>Logout</div>
                        </div>
                    </li>
                    <li>
                        <span className='versionNumber'>v{ packageJson.version }</span>
                    </li>
                </ul>
            </CSSTransition>
        </div>
    )
}

export default AppInlineProfile
