
const Loading = () => {
    return (
        <p>
            <i className='text-2xl mx-2 fa fa-spinner fa-spin'></i>
            Loading
        </p>
    )
}

export default Loading