import { Column } from 'components/DataTable/Column'
import { DataTable } from 'components/DataTable/DataTable'
import { Panel } from 'primereact/panel'
import React from 'react'
import { useQuery } from '@apollo/client'
import Loading from 'components/Loading'
import { Button } from 'primereact/button'

import './CarrierManagement.css'
import { useState } from 'react'
import InsuranceCarrierDialog from 'components/ADR/common/InsuranceCarrierDialog'
import {
    listCompanyInsuranceCarriers
} from 'graphql/api/CompanyInsuranceCarrier.js'


const CarrierManagement = ({ insuranceLineId }) => {
    const [showCreateModal, setSetshowCreateModal] = useState(false)
    const [modalData, setModalData] = useState(null)
    const {
        loading: loading,
        data: data,
        refetch: refetchData
    } = useQuery(listCompanyInsuranceCarriers)

    if (loading) {
        return <Loading />
    }

    return (
        <>
            <InsuranceCarrierDialog
                visible={showCreateModal}
                setVisible={setSetshowCreateModal}
                refetchQuery={refetchData}
                data={modalData}
            />
            <Panel
                headerTemplate={
                    <div className='mainHeader border-round-top'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <span className='' style={{ fontSize: '24px', fontWeight: 'bold' }}>
                                    Carrier Management
                                </span>
                            </div>
                            <Button
                                className='p-button-outlined'
                                type='button'
                                style={{ width: 'auto' }}
                                onClick={() => {
                                    setSetshowCreateModal(true)
                                    setModalData(null)
                                }}
                            >
                                <i className='fa fa-plus mr-2'></i>
                                Add Carrier
                            </Button>
                        </div>
                    </div>
                }
            >
                <DataTable
                    id='carrier'
                    value={data.listCompanyInsuranceCarriers.items}
                    paginator={true}
                    paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
                    currentPageReportTemplate='Showing {first} to {last} of {totalRecords} entries'
                    rows={20}
                    rowsPerPageOptions={[20, 40, 100]}
                    className='p-datatable-gridlines p-datatable-sm'
                >
                    <Column
                        field='id'
                        filter
                        sortable
                        sortField='id'
                        header='Carrier Id'
                        style={{ textAlign: 'center' }}
                        body={(row) => {
                            return <div
                                className="w-100"
                                style={{ 'cursor': 'pointer' }}
                                onClick={() => {
                                    setSetshowCreateModal(true)
                                    setModalData(row)
                                }}
                            >
                                <span className="text-primary">{row.id}</span>
                            </div>
                        }}
                    />

                    <Column
                        sortable
                        filter
                        filterMatchMode='contains'
                        header='Carrier Name'
                        field="name"
                    />

                    <Column
                        sortable
                        filter
                        filterMatchMode='contains'
                        header='Claim Reporting Instruction'
                        field="claimReportingInstruction"
                    />

                    <Column
                        sortable
                        filter
                        filterMatchMode='contains'
                        header='City'
                        field='city'
                    />

                    <Column
                        sortable
                        filter
                        filterMatchMode='contains'
                        header='State'
                        field="state"
                    />
                </DataTable>
            </Panel>
        </>
    )
}

CarrierManagement.propTypes = {}

export default CarrierManagement
