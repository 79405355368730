import classNames from 'classnames'
import 'font-awesome/css/font-awesome.css'
import 'fullcalendar/dist/fullcalendar.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import { Button } from 'primereact/button'
import 'primereact/resources/primereact.min.css'
import React, { useState } from 'react'

import { withRouter } from 'react-router-dom'
import NewMessage from './common/NewMessage'
import './App.css'
import { AppFooter } from './AppFooter'
import { FreeAppRouter } from './FreeAppRouter'
import AppTopbar from './AppTopbar'


const App = (props) => {
    const [menuActive, setMenuActive] = useState(false)
    const [profileMode, setProfileMode] = useState('inline')
    const [profileExpanded, setProfileExpanded] = useState(false)
    const [menuMode, setMenuMode] = useState('static')
    const [overlayMenuActive, setOverlayMenuActive] = useState(false)
    // set body width to 100% when menu is hidden
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(true)
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false)
    const [topbarMenuActive, setTopbarMenuActive] = useState(false)
    const [activeTopbarItem, setActiveTopbarItem] = useState(null)
    const [configActive, setConfigActive] = useState(false)
    const [inputStyle, setInputStyle] = useState('outlined')
    const [ripple, setRipple] = useState(false)
    const [newMsgVisible, setNewMsgVisible] = useState(false)

    let menuClick = false
    let topbarItemClick = false
    let configClick = false

    const onDocumentClick = () => {
        if (!topbarItemClick) {
            
            setActiveTopbarItem(null)
            setTopbarMenuActive(false)
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false)
            }

            hideOverlayMenu()
        }

        if (configActive && !configClick) {
            setConfigActive(false)
        }

        menuClick = false
        topbarItemClick = false
        configClick = false
    }

    const onMenuButtonClick = (event) => {
        menuClick = true
        setTopbarMenuActive(false)

        if (menuMode === 'overlay') {
            setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive)
        } else {
            if (isDesktop()) setStaticMenuDesktopInactive((prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive)
            else setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive)
        }

        event.preventDefault()
    }

    const onTopbarMenuButtonClick = (event) => {
        topbarItemClick = true
        setTopbarMenuActive((prevTopbarMenuActive) => !prevTopbarMenuActive)
        hideOverlayMenu()
        event.preventDefault()
    }

    const onTopbarItemClick = (e) => {
        topbarItemClick = true

        if (activeTopbarItem === e.item) setActiveTopbarItem(null)
        else setActiveTopbarItem(e.item)

        e.originalEvent.preventDefault()
    }

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false)
        setStaticMenuMobileActive(false)
    }

    const isDesktop = () => {
        return window.innerWidth > 1024
    }

    const isHorizontal = () => {
        return menuMode === 'horizontal'
    }

    const isSlim = () => {
        return menuMode === 'slim'
    }

    let containerClassName = classNames('layout-wrapper', {
        'menu-layout-static': menuMode !== 'overlay',
        'menu-layout-overlay': menuMode === 'overlay',
        'layout-menu-overlay-active': overlayMenuActive,
        'menu-layout-slim': menuMode === 'slim',
        'menu-layout-horizontal': menuMode === 'horizontal',
        'layout-menu-static-inactive': staticMenuDesktopInactive,
        'layout-menu-static-active': staticMenuMobileActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': !ripple,
    })

    return (
        <div className={ containerClassName } onClick={ onDocumentClick }>
            <NewMessage
                visible={ newMsgVisible }
                onClose={ () => {
                    setNewMsgVisible(false)
                } }
                position={ {top: 0, left: 0} }
            />
            <div className='layout-wrapper-content'>
                <AppTopbar
                    topbarMenuActive={ topbarMenuActive }
                    profileMode={ profileMode }
                    horizontal={ isHorizontal() }
                    activeTopbarItem={ activeTopbarItem }
                    onMenuButtonClick={ onMenuButtonClick }
                    showMenuButton={ false }
                    showUserSessionPanel={ false }
                    onTopbarMenuButtonClick={ onTopbarMenuButtonClick }
                    onTopbarItemClick={ onTopbarItemClick }
                ></AppTopbar>
                <div className='layout-main'>
                    { props.error ? (
                        <>
                            <h2>An unexpected error has occurred</h2>
                            <Button
                                label='Click here to reset!'
                                onClick={ () => {
                                    window.location = '#'
                                    window.location.reload()
                                } }
                            />
                        </>
                    ) : (
                        <FreeAppRouter />
                    ) }
                </div>
                <div className='layout-mask modal-in'></div>
                <AppFooter />
            </div>
        </div>
    )
}

export default withRouter(App)
