import { gql} from '@apollo/client'


const listAsgUsers = gql`
  query listAsgUsers {
    listAsgUsers {
        items {
            id
            email
            firstName
            lastName
            createdAt
            _version
            _lastChangedAt
            _deleted
        }
    }
  }`

const createAsgUser = gql`
  mutation createAsgUser($input: CreateAsgUserInput!) {
    createAsgUser(input: $input) {
      id
    }
  }`

const deleteAsgUser = gql`
  mutation deleteAsgUser($id: Int!) {
    deleteAsgUser(id: $id) {
      id
    }
  }`

const updateAsgUser = gql`
  mutation updateAsgUser($input: UpdateAsgUserInput!) {
    updateAsgUser(input: $input) {
      id
    }
  }`


export {
    listAsgUsers,
    createAsgUser,
    deleteAsgUser,
    updateAsgUser
}
