import Auth from '@aws-amplify/auth'

const apiURL = getApiUrl()

// temporary solution until we have a domain name for the new api
function getApiUrl() {
    // old api which use dynamoDb
    if (process.env.REACT_APP_STAGE === 'production') {
        return 'https://prod.asgardapi.com'
    }
    if (process.env.REACT_APP_STAGE === 'staging') {
        return 'https://zu9nrz7656.execute-api.us-east-1.amazonaws.com/dev'
    }
    // default to dev
    return 'https://nd0ezhs50j.execute-api.us-east-1.amazonaws.com/dev'
}

// temporary solution due there is not api maping with domain name
function getNewApiUrl(endpoint) {
    // new API which use RDS postgres
    let newAPiEndpoints = [
        'store',
        'notification'
    ]
    if (newAPiEndpoints.includes(endpoint.split('/')[0])) {
        if (process.env.REACT_APP_STAGE === 'production') {
            return 'https://prod.asgardapi.com'
        }
        if (process.env.REACT_APP_STAGE === 'staging') {
            return 'https://cjanxfk7lc.execute-api.us-east-1.amazonaws.com/qa'
        }
        // default to dev
        return 'https://etlngt7lf4.execute-api.us-east-1.amazonaws.com/dev'
    }
    return apiURL
}

const sessionStorageKey = 'asg-web-cognito'
const sessionStorageUserSession = 'user-session'
const sessionStorageCognitoAuth = 'cognito-auth'

async function upload(signedUrl, file) {
    const config = {
        method: 'PUT',
        body: file,
        headers: {
            'Access-Control-Allow-Origin': '*'
        },
    }

    return window.fetch(signedUrl, config).then(async(response) => {
        const data = await response

        if (response.ok) {
            return data
        } else {
            var error = new Error(response.statusText)
            error.response = response
            return Promise.reject(error)
        }
    })
}

async function pdf(endpoint, {body, method, headers: customHeaders, ...customConfig} = {}) {
    const token = window.sessionStorage.getItem(sessionStorageKey)

    const config = {
        method: method ? method : 'POST',
        body: body ? JSON.stringify(body) : undefined,
        headers: {
            authorization: token ? `${ token }` : undefined,
            'Content-Type': body ? 'application/pdf' : undefined,
            ...customHeaders,
        },
        ...customConfig,
    }

    return window.fetch(`${ apiURL }/${ endpoint }`, config).then(async(response) => {
        // Session expired
        if (response.status === 401) {
            window.sessionStorage.removeItem(sessionStorageKey)
            window.sessionStorage.removeItem(sessionStorageUserSession)
            window.sessionStorage.removeItem(sessionStorageCognitoAuth)

            await Auth.signOut()
            window.location.assign(window.location) //window.location.reload()
            return Promise.reject({message: 'Please re-authenticate.'})
        }

        // When a report request evaluates to contain zero results, we
        // attach a custom http header to the response. The fetch API requires
        // you to access custom response headers as below.
        let apiMessage = response.headers.get('apimessage')
        if (apiMessage && apiMessage !== '') {
            console.log(apiMessage)
            return Promise.reject(apiMessage)
        }

        const data = await response.blob()

        if (response.ok) {
            return data
        } else {
            var error = new Error(response.statusText)
            error.response = response
            return Promise.reject(error)
        }
    })
}

async function client(endpoint, {body, method, headers: customHeaders, ...customConfig} = {}) {
    const token = window.sessionStorage.getItem(sessionStorageKey)

    const config = {
        method: method ? method : 'POST',
        body: body ? JSON.stringify(body) : undefined,
        headers: {
            authorization: token ? `${ token }` : undefined,
            'Content-Type': body ? 'application/json' : undefined,
            Accept: body ? 'application/json' : undefined,
            ...customHeaders,
        },
        ...customConfig,
    }

    // temporary solution due there is not api maping with domain name
    let _apiURL = getNewApiUrl(endpoint)

    return window.fetch(`${ _apiURL }/${ endpoint }`, config).then(async(response) => {
        if (response.status === 401) {
            window.sessionStorage.removeItem(sessionStorageKey)

            await Auth.signOut()
            window.location.assign(window.location) //window.location.reload()
            return Promise.reject({message: 'Please re-authenticate.'})
        }

        let apiMessage = response.headers.get('apimessage')
        if (apiMessage && apiMessage !== '') {
            console.log(apiMessage)
            return Promise.reject(apiMessage)
        }

        const data = await response.json()

        if (response.ok) {
            return data
        } else {
            var error = new Error(response.statusText)
            error.response = response
            return Promise.reject(error)
        }
    })
}

export { client, upload, pdf }
