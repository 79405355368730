import {gql} from '@apollo/client'


const listClaimCreators = gql `
    query listClaimCreators {
        listClaimCreators {
            items {
                id
                firstName
                lastName
                email
                phone
                _version
                _lastChangedAt
                _deleted
            }   
        }
  }`

const listClaimCreatorsByProjectId = gql `
    query listClaimCreatorsByProjectId($projectId: Int!) {
        listClaimCreators(filter: {projectId: $projectId}) {
            items {
                id
                firstName
                lastName
                email
                phone
                _version
                _lastChangedAt
                _deleted
            }
        }
    }`


const createClaimCreator = gql `
    mutation createClaimCreator($input: CreateClaimCreatorInput!) {
        createClaimCreator(input: $input) {
            id
        }
  }`

const deleteClaimCreator = gql `
    mutation deleteClaimCreator($id: Int!) {
        deleteClaimCreator(id: $id) {
            id
        }
    }`

const updateClaimCreator = gql `
    mutation updateClaimCreator($input: UpdateClaimCreatorInput!) {
        updateClaimCreator(input: $input) {
            id
        }
    }`

export {
    listClaimCreators,
    createClaimCreator,
    deleteClaimCreator,
    updateClaimCreator,
    listClaimCreatorsByProjectId
}
