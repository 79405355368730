import React from 'react'

const UserSessionContext = React.createContext({
        messages: [],
        setMessages: () => {},
        diaries: [],
        setDiaries: () => {},
        userId: '',
        name: '',
        email: '',
        companyId: '',
        companyIdNumber: '',
        clients: [],
        users: [],
        groups: []
    }
)

export default UserSessionContext
