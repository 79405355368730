import { DataTable as PrimeDataTable} from 'primereact/datatable'
import { useState, useEffect } from 'react'


const DataTable = (props) => {
    const { ...rest } = props
    const [filterDisplay, setFilterDisplay] = useState('row')


    useEffect(() => {
        // check if one of the columns is a filter and set filterDisplay to 'menu'
        // to dont show the filter row empty
        const hasFilter = props.children.some((child) => {
            return child?.props?.filter
        })
        if (!hasFilter) {
            setFilterDisplay('menu')
        }
    }, [props.children])

    return (
        <PrimeDataTable
            filterDisplay={filterDisplay}
            stripedRows={false}
            {...rest}
            
        />
    )
}

export  {
    DataTable
}
