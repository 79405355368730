import {
  useMutation as clientUseMutation,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import Swal from 'sweetalert2'
import Auth from '@aws-amplify/auth'

const useMutation = (query, params={}) => {
  params['onError'] = () => {}
  return clientUseMutation(query, params)
}

const sessionStorageKey = 'asg-web-cognito'
const sessionStorageUserSession = 'user-session'
const sessionStorageCognitoAuth = 'cognito-auth'

const errorLink = onError(async({graphQLErrors, networkError}) => {
  
  if (networkError) {
    if (networkError.statusCode === 401) {
        // session expired
        window.sessionStorage.removeItem(sessionStorageKey)
        window.sessionStorage.removeItem(sessionStorageUserSession)
        window.sessionStorage.removeItem(sessionStorageCognitoAuth)
        await Auth.signOut()
        window.location.assign(window.location)
    }
  }
  else if (graphQLErrors) {
    let errorType = graphQLErrors[0].errorType || '400 Bad Request'
    Swal.fire({
        text: `Error: ${errorType}`,
        icon: 'error',
        confirmButtonColor: 'var(--primary-color)',
        confirmButtonText: 'Ok',
    })
  }
})

export {useMutation, errorLink}
