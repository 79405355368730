// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

console.log(process.env.REACT_APP_STAGE)
const getAwsConfig = () => {
    if (process.env.REACT_APP_STAGE === 'production') {
        return {
            // no deploy to prod yet
        }
    }
    if (process.env.REACT_APP_STAGE === 'staging') {
        return {
            aws_project_region: 'us-east-1',
            aws_content_delivery_bucket: 'asgqa',
            aws_content_delivery_bucket_region: 'us-east-1',
            aws_content_delivery_url: 'http://qa.asgard.s3-website-us-east-1.amazonaws.com',
            aws_cognito_region: 'us-east-1',
            aws_user_pools_id: 'us-east-1_jOuMpI0RM',
            aws_user_pools_web_client_id: '5k4eksv392buieeju38i6ql52f',
            
            aws_appsync_graphqlEndpoint: 'https://dfmqhlxyrzep5d4b3fjh4c4ody.appsync-api.us-east-1.amazonaws.com/graphql',
            aws_appsync_region: 'us-east-1',
            aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
        }
    }
    // default to dev
    return {
        aws_project_region: 'us-east-1',
        aws_content_delivery_bucket: 'asgdev',
        aws_content_delivery_bucket_region: 'us-east-1',
        aws_content_delivery_url: 'http://dev.asgard.s3-website-us-east-1.amazonaws.com',
        aws_cognito_region: 'us-east-1',
        aws_user_pools_id: 'us-east-1_QTCk5pyJr',
        aws_user_pools_web_client_id: '17cjif7610dks973us2kikv0iq',

        aws_appsync_graphqlEndpoint: 'https://xt5nzpyz7bbqrklcagzvl6vsvq.appsync-api.us-east-1.amazonaws.com/graphql',
        aws_appsync_region: 'us-east-1',
        aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    }
}

const AWS_CONFIG = getAwsConfig()

const awsmobile = {
    aws_project_region: AWS_CONFIG.aws_project_region,
    aws_content_delivery_bucket: AWS_CONFIG.aws_content_delivery_bucket,
    aws_content_delivery_bucket_region: AWS_CONFIG.aws_content_delivery_bucket_region,
    aws_content_delivery_url: AWS_CONFIG.aws_content_delivery_url,
    // aws_cognito_identity_pool_id: isProd() ? 'N/A' : 'us-east-1:a523e3bd-bbe2-46a0-8d58-1fd1a085ad4b',
    aws_cognito_region: AWS_CONFIG.aws_cognito_region,
    aws_user_pools_id: AWS_CONFIG.aws_user_pools_id,
    aws_user_pools_web_client_id: AWS_CONFIG.aws_user_pools_web_client_id,
    aws_appsync_graphqlEndpoint: AWS_CONFIG.aws_appsync_graphqlEndpoint,
    aws_appsync_region: AWS_CONFIG.aws_appsync_region,
    aws_appsync_authenticationType: AWS_CONFIG.aws_appsync_authenticationType,
    aws_appsync_apiKey: AWS_CONFIG.aws_appsync_apiKey
}

export default awsmobile
