import { Authenticator } from '@aws-amplify/ui-react'

import '@aws-amplify/ui-react/styles.css'
import * as Sentry from '@sentry/react'

import { Amplify, Auth, Hub } from 'aws-amplify'

import React, { useEffect, useState } from 'react'
import aws_exports from '../aws-exports'
import FreeApp from './FreeApp'
import UserSessionContext from './context/UserSessionContext'
import AppSyncConfig from 'aws-exports'
import {errorLink} from 'graphql/ApiClient'
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    from,
    split,
    createHttpLink,
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import Loading from './Loading'
  
Amplify.configure(aws_exports)
  
  
function ApolloClientWrapper({children, cognitoAuth}) {
    const [client, setClient] = useState(null)

    useEffect(() => {
        if (!cognitoAuth) {
            return
        }
        const httpLink = createHttpLink({
            uri: AppSyncConfig.aws_appsync_graphqlEndpoint,
            headers: {
                Authorization: cognitoAuth?.idToken,
            }
        })
        // create websocket link and specify protocol
        const wsLink = createSubscriptionHandshakeLink(
            {
                url: AppSyncConfig.aws_appsync_graphqlEndpoint,
                region: AppSyncConfig.aws_project_region,
                auth: {
                    type: 'AMAZON_COGNITO_USER_POOLS',
                    jwtToken: cognitoAuth?.idToken,
                }
            }
        )
        const splitLink = split(
            ({ query }) => {
                const definition = getMainDefinition(query)
                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                )
            },
            wsLink,
            httpLink
        )
        const _client = new ApolloClient({
            link: from([errorLink, splitLink]),
            cache: new InMemoryCache(),
        })


        setClient(_client)
    }, [cognitoAuth])

    if (!client) {
        return <Loading/>
    }

    return (
        <ApolloProvider client={ client }>
            { children }
        </ApolloProvider>
    )
}


function UserSessionWrapper({cognitoAuth}) {
    const [userSession, setUserSession] = useState(
        JSON.parse(window.sessionStorage.getItem('user-session'))
    )
 
    // Second, with the cognito-validated email address, get the user data from DynamoDB
    useEffect(() => {
        if (cognitoAuth && !userSession) {
            // remove old user session when old api will be deprecated
            const awaitUserInfo = async() => {
                let _userSession = {
                    userId: cognitoAuth.sub,
                    messages: [],
                    diaries: [],
                    name: cognitoAuth.name,
                    email: cognitoAuth.email,
                    companyId: cognitoAuth['custom:company_id'],
                    companyIdNumber: cognitoAuth['custom:company_id'],
                    clients: [],
                    users: [],
                    groups: cognitoAuth.groups,
                }
                window.sessionStorage.setItem('user-session', JSON.stringify(_userSession))
                setUserSession(_userSession)
            }
            awaitUserInfo()
        }
    }, [cognitoAuth, userSession])


    return (
        <UserSessionContext.Provider value={ userSession }>
            <FreeApp/>
        </UserSessionContext.Provider>
    )
}


export default function FreeAppWrapper() {
    const [cognitoAuth, setCognitoAuth] = useState(
        JSON.parse(window.sessionStorage.getItem('cognito-auth'))
    )
    const listener = (data) => {
        // Needed to update UserSessionContext when user is signed in
        if (data.payload.event === 'signIn') {
            awaitCognitoAuth()
        }
        if (data.payload.event === 'signOut') {
            window.sessionStorage.removeItem('asg-web-cognito')
            window.sessionStorage.removeItem('cognito-auth')
            window.sessionStorage.removeItem('user-session')
            setCognitoAuth(null)
        }
    }
    Hub.listen('auth', listener)

    const awaitCognitoAuth = async() => {
        let {attributes, username, signInUserSession} = await Auth.currentAuthenticatedUser(
            { bypassCache: true }
        ).catch(() => {
            return {}
        })
        if (attributes === undefined) {
            return
        }
        let {accessToken, idToken } = signInUserSession
        let {email} = attributes
        let {jwtToken} = idToken
        Sentry.setUser({
            id: username,
            email,
            idToken: jwtToken,
            sessionStart: new Date().toISOString(),
        })
        let _cognitoAuth = {
            ...attributes,
            idToken: jwtToken,
            groups: accessToken.payload['cognito:groups'],
        }
        window.sessionStorage.setItem('asg-web-cognito', jwtToken)
        window.sessionStorage.setItem('cognito-auth', JSON.stringify(_cognitoAuth))
        setCognitoAuth(_cognitoAuth)
    }

    // First, validate Cognito auth
    useEffect(() => {
        if (!cognitoAuth) {
            awaitCognitoAuth()
        }
    }, [cognitoAuth])


    const formFields = {
        signIn: {
            username: {
                placeholder: 'Enter your Email',
                isRequired: true,
                label: 'Email:'
            },
        },
        signUp: {
            username: {
                placeholder: 'Enter your Email',
                isRequired: true,
                label: 'Email:'
            },
            name: {
                placeholder: 'Enter your First Name',
                isRequired: true,
                label: 'First Name:'
            },
            family_name: {
                placeholder: 'Enter your Last Name',
                isRequired: true,
                label: 'Last Name:'
            },
            'custom:company_code': {
                placeholder: 'Enter your Company Code',
                isRequired: true,
                label: 'Company Code:'
            },
        },
    }

    return (
        <Authenticator formFields={formFields}>
            { ({signOut, user}) => (
                <ApolloClientWrapper cognitoAuth={ cognitoAuth }>
                    <UserSessionWrapper cognitoAuth={ cognitoAuth }/>
                </ApolloClientWrapper>
            ) }
        </Authenticator>
    )
}
