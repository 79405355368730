import { gql} from '@apollo/client'


const searchClaims = gql`
    query searchClaims($search: String!,) {
        searchClaims(search: $search) {
            items {
                claimant {
                    id
                    firstName
                    middleInitial
                    birthdate
                    lastName
                }
                claimAccident {
                    id
                    injuryDate
                }
                id
                code
                clientClaimNumber
                facility
                disabilityBenefit
                claimType
                isCatastrophicLoss
                policyNumber
                policyCarrier
                policyFrom
                policyTo
                createdAt
                _version
                _lastChangedAt
                _deleted
            }
        }
    }`

const searchClaimCreatorClaims = gql`
    query searchClaimCreatorClaims($search: String!) {
        searchClaimCreatorClaims(search: $search) {
            items {
                claimant {
                    id
                    firstName
                    middleInitial
                    birthdate
                    lastName
                }
                claimAccident {
                    id
                    injuryDate
                }
                id
                code
                clientClaimNumber
                facility
                disabilityBenefit
                claimType
                isCatastrophicLoss
                policyNumber
                policyCarrier
                policyFrom
                policyTo
                createdAt
                _version
                _lastChangedAt
                _deleted
            }
        }
    }`


const listClaims = gql`
  query listClaims {
    listClaims {
        items {
            id
            code
            client {
                id
                name
            }
            employer {
                id
                customerName
            }
            adjuster {
                id
                name
            }
            claimant {
                id
                firstName
                middleInitial
                birthdate
                lastName
            }
            claimAccident {
                id
                injuryDate
            }
            clientClaimNumber
            facility
            disabilityBenefit
            claimType
            isCatastrophicLoss
            policyNumber
            policyCarrier
            policyFrom
            policyTo
            createdAt
            _version
            _lastChangedAt
            _deleted
        }
    }
  }`

const listClaimPrerequisites = gql`
    query listClaimPrerequisites {
        listEmployers {
            items {
                id
                customerName
            }
        }
        listClients {
            items {
                id
                name
            }
        }
        listAdjusters {
            items {
                id
                name
            }
        }
    }`


const getClaimFullData = gql`
    query getClaimFullData($claimId: Int!) {
        getClaim(id: $claimId) {
            id
            client {
                id
                name
            }
            employer {
                id
                customerName
            }
            adjuster {
                id
                name
            }
            claimant {
                id
                firstName
                middleInitial
                lastName
                department
                occupation
                gender
                birthdate
                ssn
                ncciCode
                employeePhone
                attorneyName
                attorneyPhone
                supervisorName
                supervisorPhone
                street1
                street2
                city
                state
                zip
            }
            claimAccident {
                id
                injuryDate
                notificationDate
                isPassengerInjury
                isResultedInDeath
                isLitigation
                accidentLocation
                bodyInjury
                wcioInjuryCode
                busNumber
                accidentDesc
                bodyParts
                estSites
            }
            claimWorkStatus {
                id
                hireDate
                recordType
                status
                isCurrentlyEmployed
                isWageContinuation
                lastDayWorked
                dateLostTimeBegan
                returnToWork
                avgWeeklyWage
            }
            code
            language
            clientClaimNumber
            facility
            disabilityBenefit
            claimType
            isCatastrophicLoss
            policyNumber
            policyCarrier
            policyFrom
            projectId
            projectLocationId
            policyTo
            createdAt
            _version
            _lastChangedAt
            _deleted
        }
        listClaimDocuments(filter: {claimId: $claimId}) {
            items {
                id
                claimId
                recordType
                s3Url
                s3RelativePath
                note
                createdAt
                updatedAt
            }
        }
        listClaimMessages(filter: {claimId: $claimId}) {
            items {
                id
                fromUserId
                fromUser {
                    id
                    firstName
                    lastName
                }
                toUserId
                toUser {
                    id
                    firstName
                    lastName
                }
                message
                createdAt
                updatedAt
                toEmail
                isClaimNote
            }
        }
        listClaimWitnesses(filter: {claimId: $claimId}) {
            items {
                id
                lastName
                firstName
                phone
                createdAt
                updatedAt
            }
        }
        listDefenseCouncils(filter: {claimId: $claimId}) {
            items {
                id
                defenseFirmName
                lawyerFirstName
                lawyerLastName
                address
                phone
                email
                assignedDate
                removedDate
            }
        }
    }`


const createClaim = gql`
  mutation createClaim($input: CreateClaimInput!) {
    createClaim(input: $input) {
      id
    }
  }`

const deleteClaim = gql`
  mutation deleteClaim($id: Int!) {
    deleteClaim(id: $id) {
      id
    }
  }`

const updateClaim = gql`
  mutation updateClaim($input: UpdateClaimInput!) {
    updateClaim(input: $input) {
      id
    }
  }`

export {
    listClaims,
    createClaim,
    deleteClaim,
    updateClaim,
    listClaimPrerequisites,
    getClaimFullData,
    searchClaims,
    searchClaimCreatorClaims
}
