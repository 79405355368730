import * as Sentry from '@sentry/react'
import 'font-awesome/css/font-awesome.css'
import 'primeflex/primeflex.css'

import 'primeicons/primeicons.css'
import 'primereact/resources/primereact.min.css'

import { createRoot } from 'react-dom/client'
import { HashRouter, Route, useLocation } from 'react-router-dom'

// import 'babel-polyfill'

import packageJson from '../package.json'
import AppWrapper from './components/AppWrapper'
import FreeAppWrapper from 'components/FreeAppWrapper'
import * as serviceWorker from './serviceWorker'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import PrimeReact from 'primereact/api'
import { FilterMatchMode } from 'primereact/api'


PrimeReact.filterMatchModeOptions = {
    text: [
        FilterMatchMode.STARTS_WITH,
        FilterMatchMode.CONTAINS,
    ],
    numeric: [
        FilterMatchMode.EQUALS
    ],
    date: [
        FilterMatchMode.EQUALS,
        FilterMatchMode.BETWEEN
    ]
}
dayjs.extend(utc)
dayjs.extend(isSameOrBefore)
dayjs.extend(LocalizedFormat)

Sentry.init({
    dsn: window.location.host.startsWith('localhost')
        ? null
        : 'https://3a030aa6b85e4ef499174c82e2510e5e@o390464.ingest.sentry.io/5233637',
    environment: process.env.REACT_APP_STAGE,
    release: 'asg-web@' + packageJson.version,
})

const container = document.getElementById('root')
const root = createRoot(container)
const portalPaths = ['/contractor-portal', '/contractor-portal/']


root.render(
    <HashRouter hashType='slash'>
        {
            portalPaths.includes(window.location.pathname) &&
            <Route
                path='/'
                component={FreeAppWrapper}
            />
        }
        {
            !portalPaths.includes(window.location.pathname) &&
            <Route
                path='/'
                component={AppWrapper}
            />
        }
    </HashRouter>
)

//
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
