import { useField } from 'informed'
import { InputText } from 'primereact/inputtext'
import PropTypes from 'prop-types'
import React from 'react'

const InformedSimpleInputText = (props) => {
    const {render, informed, userProps, ref, fieldState, fieldApi} = useField(props) // basic informed api for
                                                                                     // implementing custom fields
    const {value, error, showError} = fieldState // field state
    const {setValue, setTouched} = fieldApi // field control
    const {field, label, cols, id, ...rest} = userProps // user (parent-caller) settings

    if (props.type === 'number') {
        // if value is greater than max, set value to max
        if (props?.max && value > props.max) {
            setValue(props.max)
        }
        // if value is less than min, set value to min
        if (props?.min && value < props.min) {
            setValue(props.min)
        }
    }

    return render(
        <div className='d-flex flex-column'>
            <InputText
                { ...informed }
                { ...rest }
                id={ id }
                ref={ ref }
                value={ !value && value !== 0 ? '' : value }
                onChange={ (e) => {
                    setValue(e.target.value, e)
                    if (error) {
                        setTouched(true, e)
                    }
                } }
                onBlur={ (e) => {
                    setTouched(true, e)
                } }
                style={ showError ? {border: 'solid 1px red', ...rest?.style } : { ...rest?.style }}
            />
            {
                error && showError ? <small id={ `error${ field }` } className='p-error'>{ error }</small> : null
            }
        </div>
    )
}

InformedSimpleInputText.propTypes = {
    field: PropTypes.string.isRequired
}

export default InformedSimpleInputText
