import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { Link, useHistory } from 'react-router-dom'
import text_logo from '../assets/images/Asgard.png'
import logo from '../assets/images/asgasrd_logo_2023.png'
import UserSessionContext from './context/UserSessionContext'
import GlobalSearch from './GlobalSearch/GlobalSearch'

const AppTopbar = (props) => {
    const history = useHistory()
    const userSession = React.useContext(UserSessionContext)
    const [unreadCount, setUnreadCount] = useState(0)
    const [dueDiaryCount, setDueDiaryCount] = useState(0)
    const showMenuButton = props?.showMenuButton === undefined ? true : props.showMenuButton
    const showUserSessionPanel = props?.showUserSessionPanel === undefined ? true : props.showUserSessionPanel


    useEffect(() => {
        if (userSession?.messages) {
            setUnreadCount(userSession.messages.length)
        }
        if (userSession?.diaries) {
            let dueDiariesCount = userSession.diaries.filter((d) => !d.Reviewed && d.ReminderDate <= new Date().toISOString()).length
            setDueDiaryCount(dueDiariesCount)
        }
    }, [userSession])

    const dueDiaryIconCountIndicator = () => {
        return (dueDiaryCount > 0)
            ? <span className='topbar-badge'> { dueDiaryCount } </span>
            : <></>
    }

    const unreadMessageIconCountIndicator = () => {
        return (unreadCount > 0)
            ? <span className='topbar-badge'>{ unreadCount }</span>
            : <></>
    }

    const userSessionPanel = () => {
        if (userSession) {
            let {companyId, groups, users} = userSession

            return (
                <>
                    <li
                        className={ classNames({'active-top-menu': props.activeTopbarItem === 'diaries'}) }
                        onClick={ (e) => props.onTopbarItemClick({
                            originalEvent: e,
                            item: 'diaries'
                        }) }
                    >
                        <Link tabIndex='-1' to={ '/dashboard?view=messages' }>
                            <button className='p-link'>
                                <i className='topbar-icon fa fa-fw fa-envelope-o' />

                                { unreadMessageIconCountIndicator() }
                                <span className='topbar-item-name'>Messages</span>
                            </button>
                        </Link>
                    </li>

                    <li
                        className={ classNames({'active-top-menu': props.activeTopbarItem === 'messages'}) }
                        onClick={ (e) => props.onTopbarItemClick({
                            originalEvent: e,
                            item: 'messages'
                        }) }
                    >
                        <Link tabIndex='-1' to={ '/diaries' }>
                            <button className='p-link'>
                                <i className='topbar-icon fa fa-fw fa-bell-o' />
                                { dueDiaryIconCountIndicator() }
                            </button>
                        </Link>
                    </li>

                    <li
                        className={ classNames('search-item', {'active-top-menu': props.activeTopbarItem === 'search'}) }
                    >
                        <GlobalSearch companyId={ userSession.companyId } />

                    </li>

                </>
            )
        }
    }
    const topbarItemsClassName = classNames('topbar-items fadeInDown', {'topbar-items-visible': props.topbarMenuActive})
    const profileItemClassName = classNames('profile-item', {'active-top-menu': props.activeTopbarItem === 'profile'})
    const goDashboard = () => {
        history.push('/')
    }
    return (
        <div className='topbar clearfix'>
            <div className='topbar-left'>
                <button className='p-link' onClick={ goDashboard }>

                    <img alt='logo' src={ logo } className='topbar-logo' style={{paddingLeft: '3px'}} />
                    <img alt='text_logo' src={ text_logo } className='topbar-logo' style={{paddingLeft: '12px'}} />
                </button>
            </div>

            <div className='topbar-right'>
                {
                    showMenuButton &&
                    <button className='p-link' id='menu-button' onClick={ props.onMenuButtonClick }>
                        <i className='pi pi-angle-left'></i>
                    </button>
                }
                

                <button className='p-link' id='topbar-menu-button' onClick={ props.onTopbarMenuButtonClick }>
                    <i className='pi pi-bars'></i>
                </button>
                <ul className={ topbarItemsClassName }>
                    { (props.profileMode === 'top' || props.horizontal) && (
                        <li
                            className={ profileItemClassName }
                            onClick={ (e) => props.onTopbarItemClick({originalEvent: e, item: 'profile'}) }
                        >
                            <button className='p-link'>
                                <img alt='User' className='profile-image' src='assets/layout/images/avatar.png' />
                                <span className='topbar-item-name'>Isabel Lopez</span>
                                <span className='topbar-item-role'>Marketing</span>
                            </button>
                            <ul className='layout-menu fadeInDown'>
                                <li role='menuitem'>
                                    <button className='p-link'>
                                        <i className='pi pi-fw pi-user'></i>
                                        <span>Profile</span>
                                    </button>
                                </li>
                                <li role='menuitem'>
                                    <button className='p-link'>
                                        <i className='pi pi-fw pi-lock'></i>
                                        <span>Privacy</span>
                                    </button>
                                </li>
                                <li role='menuitem'>
                                    <button className='p-link'>
                                        <i className='pi pi-fw pi-cog'></i>
                                        <span>Settings</span>
                                    </button>
                                </li>
                                <li role='menuitem'>
                                    <button className='p-link'>
                                        <i className='pi pi-fw pi-sign-out'></i>
                                        <span>Logout</span>
                                    </button>
                                </li>
                            </ul>
                        </li>
                    ) }

                    { showUserSessionPanel && userSessionPanel() }
                </ul>
            </div>
        </div>
    )
}

export default AppTopbar
