import { gql} from '@apollo/client'


const listInitialData = gql`
  query listInitialData ($claimMessageFilter: ClaimMessageFilterInput, $dateFrom: AWSDate!, $dateTo: AWSDate!) {
    listClaimMessages (filter: $claimMessageFilter) {
        items {
            id
            fromUserId
            fromUser {
                id
                firstName
                lastName
            }
            toUserId
            toUser {
                id
                firstName
                lastName
            }
            message
            claimId
            claim {
                id
                code
            }
            status
            createdAt
            _version
            _lastChangedAt
            _deleted
            isClaimNote
            toEmail
        }
    }
    searchDiaries (dateFrom: $dateFrom, dateTo: $dateTo) {
        items {
            claim {
                id
                code
                claimant {
                    id
                    firstName
                    middleInitial
                    birthdate
                    lastName
                }
                client {
                    id
                    name
                }
                employer {
                    id
                    customerName
                }
            }
            id
            isRecurring
            owner
            reminderDate
            scheduleEndAfter
            scheduleEndDate
            scheduleEndType
            scheduleInterval
            scheduleIntervalType
            selectedDays
            startDate
            comment
            dayOfMonthStart
            companyId
            createdAt
            updatedAt
            _deleted
            _version
        }
    }
  }`



const listClaimMessages = gql`
  query listClaimMessages ($filter: ClaimMessageFilterInput) {
    listClaimMessages (filter: $filter) {
        items {
            id
            fromUserId
            fromUser {
                id
                firstName
                lastName
            }
            toUserId
            toUser {
                id
                firstName
                lastName
            }
            message
            claimId
            claim {
                id
                code
            }
            status
            createdAt
            _version
            _lastChangedAt
            _deleted
            isClaimNote
            toEmail
        }
    }
  }`

const createClaimMessage = gql`
  mutation createClaimMessage($input: CreateClaimMessageInput!) {
    createClaimMessage(input: $input) {
        id
        fromUserId
        fromUser {
            id
            firstName
            lastName
        }
        toUserId
        toUser {
            id
            firstName
            lastName
        }
        message
        claimId
        claim {
            id
            code
        }
        status
        createdAt
        _version
        _lastChangedAt
        _deleted
        isClaimNote
        toEmail
    }
  }`

const deleteClaimMessage = gql`
  mutation deleteClaimMessage($id: Int!) {
    deleteClaimMessage(id: $id) {
      id
    }
  }`

const updateClaimMessage = gql`
  mutation updateClaimMessage($input: UpdateClaimMessageInput!) {
    updateClaimMessage(input: $input) {
      id
    }
  }`

// set suscription for claim messages filter by toUserId
const onCreateClaimMessage = gql`
  subscription onCreateClaimMessage {
    onCreateClaimMessage {
      id
      fromUserId
      fromUser {
          id
          firstName
          lastName
      }
      toUserId
      toUser {
          id
          firstName
          lastName
      }
      message
      claimId
      claim {
          id
          code
      }
      status
      createdAt
      _version
      _lastChangedAt
      _deleted
      isClaimNote
      toEmail
    }
  }`

export {
    listClaimMessages,
    listInitialData,
    createClaimMessage,
    deleteClaimMessage,
    updateClaimMessage,
    onCreateClaimMessage
}
