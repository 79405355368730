import React from 'react'
import { PanelMenu } from 'primereact/panelmenu'
import './AppMenu.css'

const AppMenu = (props) => {

    return (
        <PanelMenu model={props.model} multiple={true}
            style={{
                color: 'white', 
                backgroundColor: 'none', 
                marginBottom: '10rem',
                width: '100%',
            }}
        />
    )
}

export default AppMenu
