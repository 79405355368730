import { Column as PrimeColumn } from 'primereact/column'
import { FilterMatchMode } from 'primereact/api'


const Column = (props) => {
    const { ...rest } = props

    return (
        <PrimeColumn
            {...rest}
            filterMatchModeOptions={
                [
                    {label: 'Starts with', value: 'startsWith'},
                    {label: 'Contains', value: 'contains'}
                ]
            }
        />
    )
}

// set props default values reference for future updates
// https://github.com/primefaces/primereact/blob/master/components/lib/column/ColumnBase.js
Column.defaultProps = {
    sortable: true,
    filterMatchMode: FilterMatchMode.CONTAINS,
    showFilterMatchModes: true,
    showFilterMenu: true,
    showFilterMenuOptions: true,
    showFilterOperator: true,
}

export {
    Column
}
