import { Form } from 'informed'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { useMutation } from '@apollo/client'
import React from 'react'

import InformedInputText from 'components/FormInputs/InformedInputText'
import InformedInputTextArea from 'components/FormInputs/InformedInputTextArea'

import {
    createCompanyInsuranceCarrier,
    updateCompanyInsuranceCarrier
} from 'graphql/api/CompanyInsuranceCarrier.js'
import UserSessionContext from 'components/context/UserSessionContext'
import Saving from 'components/Saving'
import { parseFormData } from 'utils'


const InsuranceCarrierInfo = ({ setVisible, refetchQuery, data }) => {
    const userSession = React.useContext(UserSessionContext)
    const [
        createCompanyInsuranceCarrierMutator, { loading: loadingCreate, }
    ] = useMutation(createCompanyInsuranceCarrier, {
        onCompleted: refetchQuery
    })
    const [
        updateCompanyInsuranceCarrierMutator, { loading: loadingUpdate, }
    ] = useMutation(updateCompanyInsuranceCarrier, {
        onCompleted: refetchQuery
    })
    const submit = (formState) => {
        let companyInsuranceCarrier = {
            input: {
                name: formState.values.name,
                state: formState.values.state,
                city: formState.values.city,
                zip: formState.values.zip,
                street1: formState.values.street1,
                street2: formState.values.street2,
                claimReportingInstruction: formState.values.claim_reporting_instruction,
            }
        }

        if (data) {
            companyInsuranceCarrier.input.id = data.id
            updateCompanyInsuranceCarrierMutator({ variables: companyInsuranceCarrier })
                .then((res) => {
                    if (res?.data?.updateCompanyInsuranceCarrier?.id) {
                        setVisible(false)
                        refetchQuery()
                    }
                })
        } else {
            createCompanyInsuranceCarrierMutator({ variables: companyInsuranceCarrier })
                .then((res) => {
                    if (res?.data?.createCompanyInsuranceCarrier?.id) {
                        setVisible(false)
                        refetchQuery()
                    }
                })
        }
    }

    return (
        <Form onSubmit={submit} disabled={loadingCreate || loadingUpdate}>
            <div className='p-fluid formgrid grid'>
                {
                    data?.id && <div className='field col-12 mx-2'>
                        Company Insurance Carrier ID:
                        <span className='text-primary mx-2'>{data?.id}</span>
                    </div>
                }
                <div className='field col-6'>
                    <InformedInputText field='name' label='Name'
                        required
                        autocomplete="off"
                        initialValue={data?.name}
                    />
                </div>

                <div className='field col-12 mx-2'>
                    <strong>Address</strong>
                    <hr className='mb-0' />
                </div>
                <div className='field col-6'>
                    <div style={{ maxWidth: '31.5rem' }}>
                        <InformedInputText field='street1' label='Street 1' required
                            initialValue={data?.street1}
                        />
                    </div>
                </div>

                <div className='field col-6'>
                    <div style={{ maxWidth: '31.5rem' }}>
                        <InformedInputText field='street2' label='Street 2'
                            initialValue={data?.street2}
                        />
                    </div>
                </div>

                <div className='field col-6'>
                    <div style={{ maxWidth: '31.5rem' }}>
                        <InformedInputText field='state' label='State' required
                            initialValue={data?.state}
                        />
                    </div>
                </div>

                <div className='field col-6'>
                    <div style={{ maxWidth: '31.5rem' }}>
                        <InformedInputText field='city' label='City' required
                            initialValue={data?.city}
                        />
                    </div>
                </div>

                <div className='field col-6'>
                    <div style={{ maxWidth: '31.5rem' }}>
                        <InformedInputText field='zip' label='Zip Code' required
                            initialValue={data?.zip}
                        />
                    </div>
                </div>
                <div className='field col-12'>
                    <InformedInputTextArea field='claim_reporting_instruction' label='Claim Reporting Instruction'
                        required
                        initialValue={data?.claimReportingInstruction}
                    />
                </div>
            </div>
            <div className="p-dialog-footer pb-0">
                <Button label="Cancel" onClick={() => setVisible(false)} className="p-button-text" type='button' />
                <Button label={loadingCreate || loadingUpdate ? <Saving /> : 'Save'} type="submit" />
            </div>
        </Form>
    )
}

const InsuranceCarrierDialog = ({ visible, setVisible, refetchQuery, data }) => {
    return (
        <Dialog header="Insurance Carrier Information"
            visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
            {
                visible && <InsuranceCarrierInfo
                    setVisible={setVisible}
                    refetchQuery={refetchQuery}
                    data={data}
                />
            }
        </Dialog>
    )
}

export default InsuranceCarrierDialog
