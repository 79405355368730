import dayjs from 'dayjs'

export const isEmpty = obj => {
    return Object.keys(obj).length === 0 && obj.constructor === Object
}

export const parseFormData = (initialValues, values) => {
    let parsed = initialValues
    Object.keys(initialValues).forEach((key) => {
        if (values[key] instanceof Date) {
            parsed[key] = dayjs(values[key]).format('YYYY-MM-DD')
        }
        else if (
            Array.isArray(values[key]) &&
            values[key].length > 0 &&
            typeof values[key][0] === 'object'
        ) {
            parsed[key] = values[key].map((item) => item.value).join(',')
        }
        else if (
            Array.isArray(values[key]) &&
            values[key].length > 0 &&
            typeof values[key][0] === 'string'
        ) {
            parsed[key] = values[key].join(',')
        }
        else if (typeof values[key] === 'boolean') {
            parsed[key] = values[key]
        }
        else if (values[key]) {
            parsed[key] = values[key]
        }
    })
    return parsed
}
