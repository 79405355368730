import UserSessionContext from 'components/context/UserSessionContext'
import dayjs from 'dayjs'
import { Badge } from 'primereact/badge'
import { Chip } from 'primereact/chip'
import { ScrollTop } from 'primereact/scrolltop'
import React, { useEffect, useState } from 'react'
import Autosuggest from 'react-autosuggest'
import { useHistory } from 'react-router-dom'
import { debounce } from 'throttle-debounce'
import {
    searchClaims,
    searchClaimCreatorClaims,
} from 'graphql/api/Claim'
import { useQuery } from '@apollo/client'
import { COGNITO_AUTH_ADMIN_GROUP } from 'constants/general'
import './GlobalSearch.css'

const GlobalSearch = ({companyId}) => {
    const [searchText, setSearchText] = useState('')
    const userSession = React.useContext(UserSessionContext)
    const { 
        loading: loadingClaims,
        data: claimsData,
        refetch: refetchClaims
    } = useQuery(
        userSession?.groups?.includes(COGNITO_AUTH_ADMIN_GROUP) ?
            searchClaims:
            searchClaimCreatorClaims,
        {
            variables: {
                search: ''
            },
            skip: !searchText
        }
    )
    const [suggestions, setSuggestions] = useState([])
    /* eslint-disable no-unused-vars */

    const [value, setValue] = useState('')
    const [SearchType, setSearchType] = useState('')

    let history = useHistory()

    const formatHeaderChip = () => {
        if (SearchType === 'CLAIMANT_NAME') return {label: 'Claimant', icon: 'pi pi-user'}
        if (SearchType === 'INJURY_DATE') return {label: 'Injury', icon: 'pi pi-calendar'}
        if (SearchType === 'CLAIM_NUMBER') return {label: 'Claim', icon: 'fa fa-hashtag'}
    }

    const renderSuggestionsContainer = ({containerProps, children, query}) => {
        return (
            <>
                <div { ...containerProps } style={ {overflow: 'auto', maxHeight: '400px', width: '320px'} }>
                    { (
                        <>
                            <div style={ {height: '20px', marginTop: '5px', fontSize: 'smaller'} }>
                                <span style={ {float: 'left', marginLeft: '10px'} }>
                                Type: &nbsp;
                                    <Chip { ...formatHeaderChip() }></Chip>
                                </span>

                                {
                                    <span style={ {float: 'right', marginRight: '10px', paddingTop: '5px'} }>
                                        Count: &nbsp;
                                        <Badge value={ suggestions?.[0]?.id ? suggestions.length: 0 } severity='success'></Badge>
                                    </span>
                                }
                            </div>
                            <hr />
                        </>
                    ) }
                    { children }
                    <ScrollTop />
                </div>
            </>
        )
    }

    const renderSuggestion = (suggestion) => {
        if (!suggestion?.id)
            return (
                <div className='nomatches'>
                    <div>
                        No matches found.
                        <br /> <br />
                        Valid searches include date of injury: <br /> e.g., 01/04/2019
                        <br />
                        <br /> or claim number: <br /> e.g., 20199910910
                        <br />
                        <br /> or claimant last name: <br /> e.g., Smith
                    </div>
                </div>
            )
        else
            return (
                <div className='result'>
                    <div>
            <span style={ {fontWeight: 'bold'} }>
              { suggestion?.claimant?.lastName }, { suggestion?.claimant?.firstName }
            </span>
                        - { suggestion.code }
                        <br />
                        <span style={ {fontSize: 'smaller'} }>
              <span style={ {fontWeight: 'bold'} }>Date of Injury:</span> &nbsp;&nbsp;&nbsp;
                            { suggestion?.claimAccident?.injuryDate ? 
                                dayjs(suggestion.claimAccident.injuryDate).format('MM/DD/YYYY'): 
                                ''
                            }
            </span>
                    </div>
                </div>
            )
    }

    const doSearch = async(searchString) => {
        if (searchString.includes('/') || searchString.includes('-')) {
            setSearchType('INJURY_DATE')
        } else {
            try {
                let num = parseInt(searchString)
                if (num) setSearchType('CLAIM_NUMBER')
                else setSearchType('CLAIMANT_NAME')
            }
            catch (e) {
                setSearchType('CLAIMANT_NAME')
            }
        }
        return refetchClaims({search: searchString})
        .then((data) => {
            if (data?.data?.searchClaims?.items?.length) {
                setSuggestions(data?.data?.searchClaims?.items)
                return
            }
            if (data?.data?.searchClaimCreatorClaims?.items?.length) {
                setSuggestions(data?.data?.searchClaimCreatorClaims?.items)
                return
            }
            setSuggestions([{id: null}])
        })
    }

    const fetchRequest = async({value}) => {
        setSearchText(value)
        doSearch(value)
    }

    const delayedFetch = debounce(375, fetchRequest)

    const onChange = (event, {newValue}) => {
        setValue(newValue)
    }

    const shouldRenderSuggestions = (value) => {
        return value?.trim().length > 0
    }

    const inputProps = {
        placeholder: 'Claim search',
        value,
        onChange: onChange,
    }

    const onSuggestionSelected = (e, selected) => {
        let url = `/claimant/${ selected.suggestion.id }`
        history.push(url)
    }

    useEffect(() => {
        if (!userSession) return undefined
    }, [userSession])

    return (
        <>
            <div className='topbar-search'>
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionSelected={ onSuggestionSelected }
                    onSuggestionsFetchRequested={ (e) => {
                        delayedFetch(e)
                    } }
                    getSuggestionValue={ (suggestion) => suggestion.code || searchText}
                    renderSuggestion={ renderSuggestion }
                    renderSuggestionsContainer={ renderSuggestionsContainer }
                    onSuggestionsClearRequested={ () => {
                        setSuggestions([])
                    } }
                    inputProps={ inputProps }
                    shouldRenderSuggestions={ shouldRenderSuggestions }
                    highlightFirstSuggestion={ true }
                    focusInputOnSuggestionClick={ false }
                    style={ {border: '4px solid black', width: '500px'} }
                />
                { loadingClaims ? <i className='fa fa-refresh fa-spin fa-3x fa-fw' /> : <i className='fa fa-search' /> }
                {/* {isFilterMode ? 'FILTERING! ' : ''} */ }
            </div>
        </>
    )
}
export default GlobalSearch
