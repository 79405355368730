import { gql} from '@apollo/client'

const listCompanyInsuranceCarriers = gql`
  query listCompanyInsuranceCarriers {
    listCompanyInsuranceCarriers {
      items {
        id
        name
        street1
        street2
        city
        state
        zip
        claimReportingInstruction
      }
    }
  }`

const getCarriersAndInsurancesById = gql`
  query getCarriersAndInsurancesById($insuranceLineId: Int!) {
    listCompanyInsuranceCarriers {
      items {
        id
        name
        street1
        street2
        city
        state
        zip
        claimReportingInstruction
      }
    }
    getInsuranceLine(id: $insuranceLineId) {
        id
        projectId
        coverageType
        carrierId
        policyExpiration
        perOccurence
        aggregate
        policyEffective
        policyLimit
        layer
        excessLevel
        deductible
        owner
    }
    listInsuranceLineDocuments(filter: {insuranceLineId: $insuranceLineId}) {
        items {
            id
            note
            owner
            s3RelativePath
            s3Url
            recordType
            insuranceLineId
        }
    }
  }`

const getCarriersAndEmployerInsurancesById = gql`
  query getCarriersAndEmployerInsurancesById($employerInsuranceLineId: Int!) {
    listCompanyInsuranceCarriers {
        items {
            id
            name
        }
    }
    getEmployerInsuranceLine(id: $employerInsuranceLineId) {
        id
        employerId
        carrierId
        policyExpiration
        perOccurence
        aggregate
        policyEffective
        policyLimit
        layer
        excessLevel
        deductible
        owner
    }
    listEmployerInsuranceLineDocuments(filter: {employerInsuranceLineId: $employerInsuranceLineId}) {
        items {
            id
            note
            owner
            s3RelativePath
            s3Url
            recordType
            employerInsuranceLineId
        }
    }
}`

const createCompanyInsuranceCarrier = gql`
  mutation createCompanyInsuranceCarrier($input: CreateCompanyInsuranceCarrierInput!) {
    createCompanyInsuranceCarrier(input: $input) {
      id
      name
      street1
      street2
      city
      state
      zip
      claimReportingInstruction
    }
  }`

const deleteCompanyInsuranceCarrier = gql`
  mutation deleteCompanyInsuranceCarrier($id: Int!) {
    deleteCompanyInsuranceCarrier(id: $id) {
      id
    }
  }`

const updateCompanyInsuranceCarrier = gql`
  mutation updateCompanyInsuranceCarrier($input: UpdateCompanyInsuranceCarrierInput!) {
    updateCompanyInsuranceCarrier(input: $input) {
      id
    }
  }`

export {
    listCompanyInsuranceCarriers,
    createCompanyInsuranceCarrier,
    deleteCompanyInsuranceCarrier,
    updateCompanyInsuranceCarrier,
    getCarriersAndInsurancesById,
    getCarriersAndEmployerInsurancesById
}
